import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const TestimonialsSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % testimonials.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const testimonials = [
        {
            content: "Shailesh did an excellent job implementing our expenses management system using React, Redux, and TypeScript. He expertly handled complex state management, seamlessly integrated with Node.js APIs, and optimized the application's performance. His proficiency in TypeScript enhanced code reliability and maintainability. Shailesh is highly recommended for any project needing advanced technical expertise in web development.",
            author: "Hritik Atala",
            imageUrl: "/assets/profile.jpg",
            link: "https://www.upwork.com/freelancers/~017f064651f332cc2f",
        },
        {
            content: "Shailesh P's Work is the Highest Quality Both Web Development and Web Design. He is always available and always fulfills Project Deadline every time. I will look forward to working with him. I Highly Recommend Shailesh P. to anyone that requires work of a very high standard.",
            author: "Keval Ranapriya",
            imageUrl: "/assets/profile.jpg",
            link: "https://www.upwork.com/freelancers/~017f064651f332cc2f",
        },
        {
            content: "I would like to express my sincere gratitude to Shailesh for their exceptional work. Thank you for bringing my vision to life and for your dedication to creating a truly remarkable landing page design. I look forward to the opportunity to work with you again in the future.",
            author: "Jamie Austin",
            imageUrl: "/assets/profile.jpg",
            link: "https://www.upwork.com/freelancers/~017f064651f332cc2f",
        },
        {
            content: "Shailesh is a very skilled developer. He will devote himself seriously to projects. Well done Shailesh.",
            author: "Tommy Heifer",
            imageUrl: "/assets/profile.jpg",
            link: "https://www.upwork.com/freelancers/~017f064651f332cc2f",
        },
        {
            content: "Shailesh is very skillful and very much dedicated to finish his task.",
            author: "Rohith Kumar",
            imageUrl: "/assets/profile.jpg",
            link: "https://www.upwork.com/freelancers/~017f064651f332cc2f",
        },
    ];
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <section id="testimonials" className="testimonials section-bg">
            <div className="container">
                <div className="section-title">
                    <h2>What Clients Say's About Me !</h2>
                </div>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} onClick={()=>window.open("https://www.upwork.com/freelancers/~017f064651f332cc2f","_blank")} className="testimonial-item cursor-pointer">
                            <p style={{minHeight: "175px"}}>
                                <i className="bx bxs-quote-alt-left quote-icon-left"/>
                                {testimonial.content}
                                <i className="bx bxs-quote-alt-right quote-icon-right"/>
                            </p>
                            <img src={testimonial.imageUrl} className="testimonial-img" alt=""/>
                            <h3>{testimonial.author}</h3>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default TestimonialsSection;