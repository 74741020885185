import React from 'react';
import TypedReactDemo from "../TypedReactDemo";

const HeroSection = () => {
    const handleSidebar = () => {
        document.querySelector("header").classList.add("left_active");
    }
    return (
        <section id="hero_content" className="d-flex flex-column justify-content-center align-items-center">
            <img
                src="/assets/lines.png" alt="lines" className="nav-menu_icon"
                onClick={handleSidebar}
            />
            <div className="hero-container" data-aos="fade-in">
                <h1>Shailesh Patil</h1>
                <p>
                    I'm
                    <TypedReactDemo strings={["Designer", "Developer", "Freelancer"]}/>
                </p>
                <a href="https://www.upwork.com/freelancers/~017f064651f332cc2f" target="_blank"
                   className="btn btn-primary hire_now_btn">Hire Now</a>
            </div>
        </section>
    );
};

export default HeroSection;